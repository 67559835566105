import React from 'react'

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 960" fill="none" width="100%" height="100%" {...props}>
    <path d="M0 429c165 0 328-284 621-284 342 0 566 115 819 115v1002H0V429z" fill="url(#gradientToTransparent)" />
    <path d="M0 429c165 0 328-284 621-284 342 0 566 115 819 115V0H0v429z" fill="#fff" />
    <defs>
      <linearGradient id="gradientToTransparent" x1="720" y1="180" x2="720" y2="1224" gradientUnits="userSpaceOnUse">
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

