import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import Link from './link'
import Accordion from './modules/accordion'
import { slugify, validateExternalLink } from '../utils'
import Quote from './modules/quote'

const RichText = ({ content, options = {}, lang="de", isCentered=false }) => {

	const ulStyle = isCentered 
	? {
		textAlign: "left",
    width: "fit-content",
    margin: "0 auto"
	}
	: {}

	// Util-Function to fall back to "de", as some values are not translatable, or are optional
	const localize = el => el[lang] || el.de

	const defaultOptions = {
		renderNode: {
			[BLOCKS.HEADING_1]: (_node, children) => (
				<h2 className="display-3 my-6">
					{children}
				</h2>
			),
			[BLOCKS.HEADING_2]: (_node, children) => (
				<h2 className="display-4 my-5">
					{children}
				</h2>
			),
			[BLOCKS.HEADING_3]: (_node, children) => (
				<h3 className="mt-4 mb-2 line-height-compact">
					{children}
				</h3>
			),
			[BLOCKS.HEADING_4]: (_node, children) => (
				<h4 className="my-4">
					{children}
				</h4>
			),
			[BLOCKS.HEADING_5]: (_node, children) => (
				<h5 className="my-4">
					{children}
				</h5>
			),
			[BLOCKS.HEADING_6]: (_node, children) => (
				<h6 className="h6 text-uppercase font-weight-bold letter-spacing-wider text-muted mb-0 mt-4">{children}</h6>
			),
			[BLOCKS.PARAGRAPH]: (_node, children) => (
				<p className="text-muted my-4">
					{children}
				</p>
			),
			[BLOCKS.UL_LIST]: (_node, children) => (
				<ul className="pl-5" style={ulStyle}>
					{children}
				</ul>
			),
			[BLOCKS.HR]: (_node, _children) => (
				<hr className="my-7" style={{ borderColor: "rgba(160, 160, 160, 0.2)" }} />
			),
			[INLINES.HYPERLINK]: (node, children) => {
				const {uri} = node.data
				if (validateExternalLink(uri)) {
					return <a href={uri} data-external-link>{children}</a>
				}
				return <Link to={`${slugify(lang)}${slugify(uri)}`}>{children}</Link>
			},
			[INLINES.ENTRY_HYPERLINK]: (node, children) => {
				const referencedEntry = node.data.target;
				return <Link to={`${slugify(lang)}${slugify(localize(referencedEntry.fields.slug))}`}>{children}</Link>
			},
			[INLINES.EMBEDDED_ENTRY]: (node, _children) => {
				const referencedEntry = node.data.target;
				
				switch (referencedEntry.sys.contentType.sys.id) {
					case "icon":
            const icon = localize(referencedEntry.fields.icon)
						return (
							<span className="icon text-primary mb-3" style={{ width: 48, height: 48 }}>
                <img 
                  src={`/icons/${icon}.svg`} 
                  alt={icon} 
                  style={{ width: 48, height: 48 }} 
                />
							</span>
						)
					case "button":				
						const { caption, styling, size, internalLink, externalLink } = referencedEntry.fields
						const internalLinkLoc = internalLink ? localize(internalLink) : null
					
						const link = internalLink 
							? `${slugify(lang)}${slugify(localize(internalLinkLoc.fields.slug))}`
							: externalLink 
								? localize(externalLink).indexOf("/", 1)
									? `${slugify(lang)}${localize(externalLink)}`
									: localize(externalLink)
								: "#"
						return (
							<Link className={`btn ${styling.de === "primary" ? "btn-primary" : "btn-secondary"} ${size && size.de === "large" ? "btn-lg" : "btn-sm"} mr-3 mt-2 lift d-inline-flex flex-align-center`} to={link}>
								{localize(caption)}
								{styling.de === "primary" && <i className="fe fe-arrow-right ml-3 pb-px"></i>}
							</Link>
            )
          case "resource":
            return localize(referencedEntry.fields.value)
					default:
						return (
							<span className="badge badge-danger badge-lg">
								Embedded inline entry '{referencedEntry.sys.contentType.sys.id}' not handled
							</span>
						)
				}
			},
			[BLOCKS.EMBEDDED_ASSET]: (node, _children) => {
				const referencedEntry = node?.data?.target;
        const asset = referencedEntry.fields

				return (
					<figure className="figure my-7">
						<img className="figure-img img-fluid rounded" src={`${localize(asset.file).url}?w=700`} alt={localize(asset.title)} />
						<figcaption className="figure-caption text-center">
							{localize(asset.title)}
						</figcaption>
					</figure>
				)
			},
			[BLOCKS.EMBEDDED_ENTRY]: (node, _children) => {
				const referencedEntry = node?.data?.target;

				switch (referencedEntry.sys.contentType.sys.id) {
					case "accordion":
						const fields = {
							title: localize(referencedEntry.fields.title),
							richText: localize(referencedEntry.fields.richText)
						}
						return (
							<Accordion {...fields} lang={lang} />
            )
          case "quote":
            const quoteFields = {
              quoteText: localize(referencedEntry.fields.quoteText),
              author: localize(referencedEntry.fields.author),
              layout: localize(referencedEntry.fields.layout)
            }
            return (
              <Quote {...quoteFields} lang={lang} />
            )
					default: 
						return (
							<span className="badge badge-danger badge-lg">
								Embedded block entry '{referencedEntry.sys.contentType.sys.id}' not handled
							</span>
						)
				}
			}
		}
	}

	return documentToReactComponents(content.json, {
		renderNode: {
			...defaultOptions.renderNode,
			...options.renderNode
		},
		renderText: text => {
			return text.split('\n').reduce((children, textSegment, index) => {
				return [...children, index > 0 && <br key={index} />, textSegment];
			}, []);
		}
	})
}

export default RichText