import React from 'react'

import RichText from '../rich-text'
import RichTextModule from '../modules/rich-text'
import Wave2 from '../shapes/wave-2'
import SEO from '../modules/seo/seo'

const TextContentLayout = ({ title, node_locale, richText, rightColumn, meta }) => (
  <div className="page-margin bg-green-100 position-relative" data-template="text-content">
    <SEO {...meta} />
    <div style={{ position: "absolute", color: "#E2FCF0", width: "100vw", top: 0 }}>
      <Wave2 />
    </div>
    <section className="py-8 pt-md-10 pb-md-8 bg-gray-200">
      <div className="container ">
        <div className="row align-items-center">
          <div className="col-12">
            <h1 className="display-3 mb-2 text-primary">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section className="pt-0 pb-5 pb-md-10">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 rich-text-margin-correction">
            <RichText content={richText} lang={node_locale} />
          </div>

          {rightColumn && (
            <div className="col-12 col-lg-4 mb-10 card-fixed">
              <RichTextModule {...rightColumn} lang={node_locale} textSmaller />
            </div>
          )}

        </div>
      </div>
    </section>
  </div>
)


export default TextContentLayout