import React, { useState } from 'react'
import RichText from '../rich-text'
import { motion } from 'framer-motion'

const ModuleAccordion = ({ open = false, title, richText, lang }) => {
  const [isOpen, setIsOpen] = useState(open)
  const titleWithLinebreaks = title.split('\n').join('<br/>')

  const toggle = event => {
    event.preventDefault()
    setIsOpen(!isOpen)
  }

  const idString = str => str.replace(/\s+/g, '-').toLowerCase()

  const variants = {
    open: { height: "auto" },
    close: { height: 0 }
  }

  return (
    <>
      <div className={`accordion card mb-5 mb-md-2 ${isOpen && "is-open"}`} id="helpAccordionOne">
        <div className="list-group">
          <div className="list-group-item text-dark px-4 py-2">
            <a className="d-flex align-items-center text-reset text-decoration-none" href={`#${idString(title)}`} role="button" onClick={toggle} aria-expanded={isOpen} aria-controls={idString(title)}>
              <span className="h3 p-4 my-0 line-height-compact" dangerouslySetInnerHTML={{ __html: titleWithLinebreaks }} />
              <div className="text-muted ml-auto line-height-compact pl-4 pr-2">
                <span className="collapse-chevron text-muted line-height-compact">
                  <i className="fe fe-lg fe-chevron-down"></i>
                </span>
              </div>
            </a>
            <motion.div variants={variants} animate={isOpen ? "open" : "close"} initial={open ? "open" : "close"} style={{ overflow: "hidden" }}>
              <div id={idString(title)} data-parent={`#${idString(title)}`}>
                <div className="px-4 mt-n2 pb-1">
                  <RichText content={{ json: richText }} lang={lang} />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModuleAccordion