import React from 'react'
import { graphql } from 'gatsby'

import TextContentLayout from '../components/layouts/text-content'

const TextContentTemplate = ({ data: { textContent: { title, node_locale, richText, rightColumn, description, metaImage, slug } } }) => {
  const data = {
    title,
    node_locale,
    richText,
    rightColumn,
    meta: {
      title,
      description,
      slug,
      imageUrl: metaImage ? metaImage.fixed.src : null,
      locale: node_locale
    }
  }
  return <TextContentLayout {...data} />
}

export const query = graphql`
  query TextContentQuery($id: String!) {
    textContent: contentfulPageTextContent(id: {eq: $id}) {
      id
      slug
      title
      description
      node_locale
      metaImage {
        fixed(width: 1200, height: 630, quality: 90, resizingBehavior:FILL) {
          src
        }
      }
      richText {
        json
      }
			rightColumn {
        id
        content {
          json
        }
        isCardLayout
        isCentered
      }
    }
  }
`

export default TextContentTemplate