import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

const Quote = ({ quoteText, author: a, layout, lang = "de" }) => {

  // helper function to filter localized content (with fallback to default)
  const localize = el => el[lang] || el.de

  const author = {
    name: localize(a.fields.name),
    jobTitle: localize(a.fields.jobTitle),
    image: localize(localize(a.fields.profilePicture).fields.file).url
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => <p className="d-inline">{children}</p>
    }
  }

  const imgSize = layout === 'Autor links – Zitat rechts' ? '256' : '96'
  const layoutClass = `quote ${layout === 'Autor links – Zitat rechts' ? 'quote-hero' : layout === 'Zitat über Autor (zentriert)' ? 'quote-centered' : ''}`

  return (
    <div className={layoutClass}>
      <q>{documentToReactComponents(quoteText, options)}</q>
      <div className="quote-author">
        <div className="quote-author-image">
          <img src={`${author.image}?w=${imgSize}&h=${imgSize}&q=70&fm=webp&fit=thumb&f=face`} alt={author.name} />
        </div>
        <div className="quote-author-name">
          <p className="h5 font-weight-bold line-height-compact m-0">{author.name}</p>
          <small className="h6 text-uppercase line-height-compact p-0 m-0 text-gray-600">{author.jobTitle}</small>
        </div>
      </div>
    </div>
  )
}

export default Quote