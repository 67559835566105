import React from 'react'
import RichText from '../rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

const ModuleRichText = ({ content, lang, isCardLayout = false, isCentered = false, backgroundDark = false, textSmaller = false }) => {

  const layout = isCardLayout ? "card shadow-lg px-6 py-4 position-relative overflow-hidden" : "w-100"
  const alignment = isCentered ? "text-center" : ""

  const options = backgroundDark ? {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => (
        <h2 className="display-3 my-6 text-white">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <h2 className="display-4 my-5 text-white">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <h3 className="mt-4 mb-2 line-height-compact text-white">
          {children}
        </h3>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <h4 className="my-4 text-white">
          {children}
        </h4>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <h5 className="my-4 text-white">
          {children}
        </h5>
      ),
    }
  } : {}
  const paragraph = textSmaller
    ? {
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="font-size-sm mb-3 text-muted">{children}</p>
      )
    }
    : {}

  return (
    <div className={[layout, alignment].join(" ")}>
      {isCardLayout && <TopLine />}
      <RichText content={content} lang={lang} isCentered={isCentered} options={{ renderNode: { ...options.renderNode, ...paragraph } }} />
    </div>
  )
}

const TopLine = () => (
  <div style={{
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: 4,
    backgroundColor: "var(--primary)"
  }} />
)

export default ModuleRichText